import { Grid } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";
import ProgressBar from "react-scroll-progress-bar";
import ScrollToTop from "react-scroll-to-top";
import MarkdownRenderer from "../components/MarkdownRenderer";
import Sponsor from "../components/Sponsor";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    border: "5px solid " + theme.palette.secondary.main,
    boxShadow: "0 4px 8px" + theme.palette.primary.main,
    borderRadius: "3px",
  },
  image: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    marginBottom: "20px",
  },
  scrollToTop: {
    background: theme.palette.secondary.main,
  },
}));
export default function Article() {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <ProgressBar
        height="5px"
        bgcolor={theme.palette.secondary.main}
        duration="1"
      />
      <Grid container justifyContent="center" spacing={3} padding={5}>
        <Grid item xs={12} md={12} lg={10}>
          <Sponsor />
        </Grid>
        <Grid item xs={12} md={12} lg={10}>
          <div elevation={3} className={classes.paper}>
            <MarkdownRenderer id={id} />
          </div>
        </Grid>
        <ScrollToTop
          smooth
          color={theme.palette.primary.main}
          className={classes.scrollToTop}
        />
      </Grid>
    </>
  );
}
