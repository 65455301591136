import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Article from "./pages/Article";
import Home from "./pages/Home"; // Import the 'Page' component
import theme from "./theme";

const useStyles = makeStyles((_theme) => {
  return {
    body: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  };
});

function App() {
  const styles = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Analytics />
      <SpeedInsights />
      <Router>
        <div className={styles.body}>
          <Navbar />
          <Routes>
            <Route path="/article/:id" element={<Article />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
