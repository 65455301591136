import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { CodeBlock, nord } from "react-code-blocks";
import { DEFAULT_MARKDOWN_RENDERERS, Markdown } from "react-marked-renderer";
import { queryApi } from "../utils";
import AsyncImage from "./AsyncImage";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontFamily: "georgia",
      fontSize: "18px",
      lineHeight: "36px",
      fontWeight: 400,
    },
    hr: {
      border: 0,
      borderTop: `2px solid ${theme.palette.primary.main}`,
      opacity: 0.5, // Adjust the opacity for the desired softness,
      width: "50%",
    },
    blockquote: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      opacity: 0.75, // Adjust the opacity for the desired softness,
      margin: 0,
      padding: "0 1em",
      marginBlock: theme.spacing(5),
    },
  };
});

const MarkdownRenderer = ({ id }) => {
  const [markdown, setMarkdown] = useState("");
  const classes = useStyles();
  const renderers = {
    ...DEFAULT_MARKDOWN_RENDERERS,
    codeblock: ({ lang, text }) => {
      return (
        <CodeBlock
          text={text}
          language={lang}
          showLineNumbers={false}
          theme={nord}
        />
      );
    },
    hr: () => {
      return <hr className={classes.hr}></hr>;
    },
    heading: ({ depth, text }) => {
      if (depth === 1) {
        return (
          <>
            <Typography variant="h1" textAlign={"center"}>
              {text}
            </Typography>
            <hr className={classes.hr}></hr>
          </>
        );
      }
      return <Typography variant={"h" + depth}>{text}</Typography>;
    },
    blockquote: ({ text }) => {
      return <blockquote className={classes.blockquote}>{text}</blockquote>;
    },

    img: ({ href }) => (
      <AsyncImage
        id={id}
        href={href}
        style={{ display: "block", margin: "auto" }}
      />
    ),
  };
  console.log("MarkdownRenderer -> renderer", renderers);

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await queryApi("getArticle", { id });
      setMarkdown(response.data);
    };

    fetchMarkdown();
  }, [id]);

  if (!markdown) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Markdown markdown={markdown} renderers={renderers} />
    </div>
  );
};

export default MarkdownRenderer;
