import axios from "axios";

export const getArticleResource = async (id, resource) => {
  const response = await queryApi("getResource", { id, resource });
  return response.data;
};

const getDevUrl = (endpoint) => "http://localhost:5001/practical-prose-c04c7/us-central1/" + endpoint
 
export const queryApi = async (endpoint, params = {}) => {
  let url = "";
  switch (endpoint) {
    case "getMetadata":
      url = "https://getmetadata-sq6bubclta-uc.a.run.app";
      break;
    case "getArticle":
      url = "https://getarticle-sq6bubclta-uc.a.run.app";
      break;
    case "getResource":
      url = "https://getresource-sq6bubclta-uc.a.run.app";
      break;
    default:
      break;
  }
  const response = await axios.get(url, {
    params,
  });
  return response;
};
