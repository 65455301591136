import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Loading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
    }}
  >
    <CircularProgress />
  </div>
);

export default Loading;
