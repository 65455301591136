import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.palette.tertiary.main + "20", // Tertiary background color
      padding: theme.spacing(3),
      border: "1px solid " + theme.palette.tertiary.main,
      borderRadius: "5px",
      position: "relative",
    },
    sponsorLabel: {
      position: "absolute",
      top: 0,
      right: 0,
      color: "gray",
      padding: theme.spacing(0.5),
      fontSize: "1em",
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(0.5),
    },
    closeButton: {
      position: "absolute",
      top: 0,
      left: 0,
      padding: theme.spacing(0.3),
    },
  };
});
const Sponsor = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }
  return (
    <Box
      className={classes.container}
      display={"flex"}
      flexDirection={"row"}
      gap={1}
      alignItems={"center"}
    >
      <div className={classes.closeButton}>
        <IconButton onClick={() => setIsVisible(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.sponsorLabel}>
        Sponsored
        <Tooltip title="This item is sponsored for monetization.">
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      </div>
      <img
        src="/sponsor.png" // Placeholder image URL
        alt="Glass Storage"
        style={{
          maxWidth: "100%",
          maxHeight: "200px",
          marginRight: "20px",
          borderRadius: "5px",
        }}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        flexGrow={1}
        justifyContent={"center"}
      >
        <Typography variant="h3" gutterBottom>
          Glass Jar Container with Airtight Bamboo Lid
        </Typography>
        <Typography variant="body1">
          This glass container comes with a cute wooden spoon and can be used to
          store practically anything in your kitchen. I use it to store ground
          coffee, loose tea leaves, and sugar so that making a hot beverage
          first thing in the morning is hassle-free and fun.
        </Typography>
        <Button
          variant="contained"
          sx={{ alignSelf: "flex-end" }}
          color="tertiary"
          LinkComponent={"a"}
          href="https://www.amazon.com/gp/product/B07WGSR8KB/ref=ppx_yo_dt_b_asin_title_o04_s00?ie=UTF8&amp;th=1&_encoding=UTF8&tag=practicalpros-20&linkCode=ur2&linkId=dc76b3e9f611667addef0a3f84b1d476&camp=1789&creative=9325"
        >
          Get it now!
        </Button>
      </Box>
    </Box>
  );
};

export default Sponsor;
