import { makeStyles } from "@mui/styles";
import React from "react";
import Articles from "../components/Articles";

const useStyles = makeStyles((theme) => {
  return {
    pageContent: {
      height: "100%",
      flex: 1,
      padding: theme.spacing(5),
    },
  };
});

const Home = () => {
  const styles = useStyles();
  return (
    <div className={styles.pageContent}>
      <Articles />
    </div>
  );
};

export default Home;
