import { Card, CardContent, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { queryApi } from "../utils";
import AsyncImage from "./AsyncImage";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow: "0 4px 8px" + theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
      border: "5px solid " + theme.palette.secondary.main,
    },
    link: {
      textDecoration: "none",
      flex: "1 1 45%",
    },
  };
});
const Articles = () => {
  const styles = useStyles();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await queryApi("getMetadata");
        setArticles(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Stack spacing={5} direction={"row"} flexWrap={"wrap"} useFlexGap>
      {isLoading && <Loading />}
      {!isLoading &&
        articles?.map((article, index) => (
          <Link
            to={"article/" + article.id}
            key={index}
            className={styles.link}
          >
            <Card key={index} className={styles.card}>
              <AsyncImage
                id={article.id}
                href="cover.jpg"
                style={{ width: "100%", height: "300px", objectFit: "cover" }}
              />
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="flex-center"
              >
                <CardContent>
                  <Typography variant="h2" gutterBottom>
                    {article.title}
                  </Typography>
                </CardContent>
              </Stack>
            </Card>
          </Link>
        ))}
    </Stack>
  );
};

export default Articles;
