import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0c6111", // Dartmouth green
    },
    secondary: {
      main: "#f8f4e3", // Latte
    },
    tertiary: {
      main: "#e5446d", // Pink
    },
  },
  typography: {
    body1: {
      fontFamily: "georgia",
      fontSize: "18px",
      lineHeight: "36px",
      fontWeight: 400,
    },
    h1: {
      fontFamily: "Georgia, serif",
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "10px 0",
    },
    h2: {
      fontFamily: "Georgia, serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      margin: "10px 0",
    },
    h3: {
      fontFamily: "Georgia, serif",
      fontSize: "1.25rem",
      fontWeight: "bold",
      margin: "10px 0",
    },
    h4: {
      fontFamily: "Georgia, serif",
      fontSize: "1.125rem",
      fontWeight: "bold",
      margin: "0",
    },
    h5: {
      fontFamily: "Georgia, serif",
      fontSize: "1rem",
      fontWeight: "bold",
      margin: "0",
    },
    subtitle1: {
      fontFamily: "Georgia, serif",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5, // Adjust the line height as needed
    },
  },
});

export default theme;
