import { useEffect, useState } from "react";
import { getArticleResource } from "../utils";
import Loading from "./Loading";

const AsyncImage = ({ id, href, style }) => {
  const [url, setUrl] = useState(null);
  const decodeHref = decodeURIComponent(href);

  useEffect(() => {
    const fetchUrl = async () => {
      const fetchedUrl = await getArticleResource(id, decodeHref);
      setUrl(fetchedUrl);
    };

    fetchUrl();
  }, [id, decodeHref]);

  if (!url) {
    return <Loading />;
  }

  return <img width="80%" src={url} style={style} />;
};

export default AsyncImage;
