import { Email } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      textAlign: "center",
      padding: "10px 0",
      marginTop: "auto",
    },
    href: {
      color: "#fff",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});
const Footer = () => {
  const classes = useStyles();
  const emailAddress = "contact-us@practical-prose.net";
  return (
    <Box component="footer" className={classes.footer}>
      <Typography variant="body1">
        <a className={classes.href} href={`mailto:${emailAddress}`}>
          <Email sx={{ marginRight: "5px" }} /> {emailAddress}
        </a>
      </Typography>
      <Typography variant="body2">
        &copy; {new Date().getFullYear()} All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
