import { AppBar, Box, Toolbar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <Box>
        <AppBar color="secondary" position="relative">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "black" }}
                sx={{ flexGrow: 1 }}
              >
                <img
                  src="/logo2.png"
                  alt="Logo"
                  style={{ height: "75px", margin: "10px" }}
                />
              </Link>
            </Box>
            {/* <StayUpdated /> */}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
